import React from 'react'
import styled, { keyframes } from 'styled-components'
import Progress from 'components/progress'
import Timeline from 'sections/about/parts/Timeline'

class TabsPart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "skills"
        }
    }

    render() {
        const TabContainer = styled.div`
            min-height: 400px;
            margin-top: 20px;
            @media (max-width:767px) {
                margin-top: 50px;
                padding: 0 20px;
            }
        `
        const TabSelectors = styled.div`
            display: flex;
        `
        
        const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `
        const TabSelector = styled.button`
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            background-color: transparent;
            border: none;
            margin: 0 10px 0 0;
            border-bottom: 2px solid #fff;
            transition: .5s;
            &:hover, &.active {
                animation: ${ColorAnimation} 10s infinite alternate;
            }
            &:focus {
                outline: none;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        `

        const Tabs = styled.div`
            margin-top: 20px;
        `

        const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `

        const Tab = styled.div`
            display: none;
            animation: ${Fade} 1s forwards;
        `

        return(
            <TabContainer>
                <TabSelectors>
                    <TabSelector className={this.state.tab === "skills" ? "active" : ""} onClick={() => this.setState({tab: "skills"})}>
                        Skills
                    </TabSelector>
                    <TabSelector className={this.state.tab === "experience" ? "active" : ""} onClick={() => this.setState({tab: "experience"})}>
                        Experience
                    </TabSelector>
                </TabSelectors>
                <Tabs>
                    <Tab style={{
                        display: this.state.tab === "skills" ? "block" : "none"
                    }}>
                        <Progress value={100} text="Unity - 9 Years" />
                        <Progress value={70} text="Unreal Engine - 5 years" />
                        <Progress value={90} text="NodeJS - 4 Years" />
                        <Progress value={80} text="HoloLens - 3 Years" /> 
                        <Progress value={85} text="ARKit/ARCore - 2.5 Years" />
                        <Progress value={60} text="Magic Leap - 1 Year" />
                        <Progress value={85} text="C# - 9 Years" />
                    </Tab>
                </Tabs>
                <Tabs>
                    <Tab style={{
                            display: this.state.tab === "experience" ? "block" : "none"
                        }}>
                        <Timeline data={{
                                "2016 - 2016" :  {
                                    title: "Gameplay Programmer",
                                    institution: "Darkshot Studios",
                                    description: "Developed core gameplay mechanics, enemy AI, player input, and UI functionality on ROTii."
                                },
                                "2017 - 2019" : {
                                    title: "AR/VR Development Intern",
                                    institution: "Tietronix Software",
                                    description: "Developed immersive applications on HoloLens, HTC Vive, Oculus Rift, WMR Headsets, and others for clients including: NASA, Lycoming, and others."
                                },
                                "2019 - Present" : {
                                    title: "Junior Developer",
                                    institution: "Groove Jones",
                                    description: "Developed immersive applications for virtual and augmented reality devices utilizing Unity, Unreal, and other development tools."
                                },
                                "2016 - Present" : {
                                    title: "Freelance Developer",
                                    institution: "Independent",
                                    description: "Developed software and web applications for clients. This includes 3D immersive applications, websites, mobile apps, and more."
                                }
                            }
                        }
                    />
                    </Tab>
                </Tabs>
            </TabContainer>
        )
    }
}

export default TabsPart